import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbNews } from '../Constants';
import { Link } from "react-router-dom";
import { Mobile } from "../misc/utils";

const AnyDayNews = () => {
  const [news, setNews] = useState([]);
  const [error, setError] = React.useState(null);
    
    useEffect(() => {
      const fetchNews = () => {
        axios.get(dbNews)
        .then(response => {
          setNews(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchNews()
    }, [])

  if (error) return `Error: ${error.message}`;

  const isMobile = Mobile();

    if (news.length < 1) {
        return 
    } 

  const currentNews = news.map(value => (
    <div key={value.id}>
        {(isMobile && value.name) && 
            <div className="row mx-5 mb-2 border rounded bg-body bg-secondary">
                <div className="col p-2 bg-secondary">
                    <img src={value.picture_link} alt="" 
                    className="img-fluid rounded"></img>

                    <div className="card mt-2 py-3 Description bg-info text-white">
                        <div className="text-center">
                            <div className="Arial22"><b>{value.text}:  </b></div>
                                <b>
                                {value.name &&
                                    <Link style={{ fontSize: value.fontsize_list + "px" }}
                                    to={value.page}>{value.name}</Link>
                                }   
                                </b>
                        </div>  
                    </div>

                </div>
            </div>
        }
        {/* {(isMobile && value.post_name) && 
            <div className="row m-2 pb-3 border rounded bg-body bg-secondary">
                <div className="col p-2 bg-secondary">
                    <img src={value.post_picture_link} alt="" 
                    className="img-fluid rounded"></img>

                    <div className="card mt-2 py-3 Description bg-info text-white">
                        <div className="text-center">
                            <div className="Arial22"><b>{value.text}:  </b></div>
                                {value.post_name &&
                                    <Link style = {{'fontSize': '16px'}} className="font-weight-bold text-wrap-2" 
                                    to={value.post_page}>{value.post_name}</Link>
                                }   
                        </div>  
                    </div>

                </div>
            </div>
        } */}

        {!isMobile &&
            <div className="row mb-2 pb-1 border-bottom">
                {value.name &&
                <>
                    <div className="col-sm-3">
                        <div></div>
                    </div>
                    {/* kolonne 1 */}
                    <div className="col-sm-1 mt-1 pt-2 bg-light border border-5 text-center">
                        <img src={value.picture_link} alt="" 
                        className="img-fluid rounded img-news-max-height"></img>
                    </div>
                    {/* kolonne 2 */}
                    <div className="col-sm-4 mt-1 p-2 bg-light border-5 border-end border-top border-bottom">
                        <div className="card Description bg-info text-white" style = {{'--bs-bg-opacity': '.8'}}>
                            <div className="text-center">
                                <div className="Arial22"><b>{value.text}:  </b></div>
                                    {value.name &&
                                        <b>
                                        <Link style = {{'fontSize': '16px'}} 
                                        to={value.page}>{value.name}</Link>
                                        </b>
                                    }   
                            </div>  
                        </div>
                    </div> 
                </>  
                }
                

                 {/* kolonne 3 */}
                 {/*
                <div className="col-sm-4 d-flex align-items-center justify-content-center">  
                    {value.quote &&
                        <div className="card pt-2 px-2 bg-info text-white">
                            <div className="quote3 mb-3">{value.quote}</div> 
                            <div className="blockquote-footer text-white"><i>{value.quote_source}</i></div>
                        </div>
                    }
                    {value.quote_quote &&
                        <div className="card pt-2 px-2 bg-info text-white">
                            <div className="quote3 mb-3">{value.quote_quote}</div> 
                            <div className="blockquote-footer text-white"><i>{value.source_txt} ({value.person})</i></div>
                        </div>
                    }
                
                </div> */}
                <div className="col-sm-4">
                        <div></div>
                    </div>
            </div>

        }

    </div>  
    ))

  const {active} = news[0];

  if (active === true) {
    return currentNews
  } else {
     return <div></div>
    }

}

export default AnyDayNews
