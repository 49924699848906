import React, { useEffect, useState } from "react"
import axios from 'axios';
import { dbURLists, dbURL, dbURListsRefs } from '../Constants';
import '../lists.scss';
import SEO from '../../SEO';
import { animateScroll as scroll } from "react-scroll";
import {DebounceInput} from 'react-debounce-input';
import { AgGridReact } from 'ag-grid-react';
import ReactMarkdown from 'react-markdown';
import { useParams, Link } from 'react-router-dom';
import ReactPlayer from "react-player";
import ReadMoreAndLess from "react-read-more-less";
import { SearchLists, SelectProperty, calculateAge, Mobile } from "../misc/utils";
import Select from 'react-select';

const PeopleLists = (props) => {
    const [people, setPeople] = useState([])
    const [list, setList] = useState([])
    const [loadingPeople, setLoadingPeople] = useState(true)
    const [references, setReferences] = useState([])
    const [loadingList, setLoadingList] = useState(true)
    const [errorPeople, setErrorPeople] = useState(null)
    const [errorList, setErrorList] = useState(null)
    const [errorReferences, setErrorReferences] = useState(null)
    const [optionCountry, setOptionCountry] = useState(null)
    const [optionNationality, setOptionNationality] = useState(null)
    const [searchString, setSearchString] = useState('')
    const [AgGrid, setAgGrid] = useState(false)
    const [columnDefs] = useState([
        {headerName: "#", field: "place", resizable: true, width: 50, cellStyle: { 'font-size': '14px' }},
        {headerName: "Songwriter", field: "songwriter", resizable: true, width: 300, cellStyle: { 'font-size': '14px' }},
        {headerName: "Born - city", field: "city", sortable: true, width: 160, cellStyle: { 'font-size': '14px' }},
        {headerName: "Region", field: "region", filter: true, sortable: true, width: 150, cellStyle: { 'font-size': '14px' }},
        {headerName: "Country", field: "country", filter: true, sortable: true, cellStyle: { 'font-size': '14px' }}
      ])
    
    const params = useParams();
    
    let filterArray, CountryOptions, NationalityOptions, sortedReferences, sortedPeople

    useEffect(() => {
        const fetchList = () => {
            axios.get(dbURLists + params.slug)
            .then(response => {
                setLoadingList(false)
                setList(response.data)
            }).catch(errorList => {
                setErrorList(errorList);
            });
        }

        const fetchPeople = () => {
            axios.get(dbURL + props.PeopleAPI + params.slug)
            .then(response => {
                setLoadingPeople(false)
                setPeople(response.data)
            }).catch(errorPeople => {
                setErrorPeople(errorPeople);
            });
        }

        const fetchReferences = () => {
            axios.get(dbURListsRefs + params.slug)
            .then(response => {
                setReferences(response.data)
            }).catch(errorReferences => {
                setErrorReferences(errorReferences);
            });
        }

        fetchList()
        fetchPeople()
        fetchReferences()
      }, [params.slug, props.PeopleAPI])
  
    if (errorList) return `Error List: ${errorList.message}`;
    if (errorPeople) return `Error ListInProgress: ${errorPeople.message}`;
    if (errorReferences) return `Error References: ${errorReferences.message}`;

    const isMobile = Mobile();

    CountryOptions = SelectProperty(people, 'country')
    NationalityOptions = SelectProperty(people, 'nationality')

    const selectedOptionCountry = (selectedCountry) => {
        setOptionCountry(selectedCountry);
    }
    const selectedOptionNationality = (selectedNationality) => {
        setOptionNationality(selectedNationality);
    }

    const checkHandler = () => {
        setAgGrid(!AgGrid)
      }

    const scrollToBottom = () => {
        scroll.scrollToBottom();
      };

    const scrollToTop = () => {
        scroll.scrollToTop(); 
    };

    if (loadingList || loadingPeople) {
        return <div>
                <main className="my-4 py-4"><div className="container"> 
                <div className="row pt-5"><div className="col">
                <h4>Loading...</h4></div></div></div></main>;
            </div>
    }

    if (list.length < 1) {
        return <div>
                    <main className="my-1 py-1">
                        <div className="container">  
                            <div className="row pt-1">
                                <div className="col">
                                    <h1>404 - Not Found!</h1>
                                    <Link to="/lists">
                                        Go to current Lists
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </main>
            </div> 
    }

    const {name, description, pic_link, page, ranked_list, hide_grid_option, no_filters, nationality, sort_decending } = list[0];

    sortedReferences = references.sort((a, b) => {
        return b.ref_number - a.ref_number;
    });

    //SORTERING
    if (sort_decending) {
        sortedPeople = people.sort((a, b) => {
            return b.place - a.place;
        });
    }

    if (!sort_decending){
        sortedPeople = people;
    } 

    const handleSearchChange = (e) => {
        setSearchString(e.target.value);
      };
    
    const clearSearchField = (e) => {
        setSearchString('')
        }
    
    if (searchString) {
        filterArray = SearchLists(sortedPeople, 'artist', searchString.toLowerCase());
        } 
    if (optionCountry) {
        filterArray = sortedPeople.filter(e => e.country === optionCountry.value);
        } 
    if (optionNationality) {
        filterArray = sortedPeople.filter(e => e.nationality === optionNationality.value);
        } 
    if (!searchString && !optionCountry && !optionNationality) {
        filterArray = sortedPeople;
    }

    const listHeading = list.map(value => {
        
        return (
            <React.Fragment key={value.id}>

                <h1>{value.name}</h1>

                <div className="row my-3">

                     {/* kolonne 1 */}
                     <div className="col-sm-3">
                                            
                            <img src={value.picture_link}
                        alt="alt" className="img-fluid" /> 

                        {
                            (() => {
                            if (value.ListPic2) {
                            return  <div className='mt-2'><img src={value.ListPic2} alt="alt" className="img-fluid" /> </div>
                            }
                            return 
                            })()
                        }

                        
                        {((window.location.hostname === "localhost") || (value.in_progress)) &&
                            <div>
                            <br />
                            <div className="alert-sm alert-success text-center mb-1"><b>In progress</b></div> 
                            
                            <br />
                            <div className="text-center">
                                <div className="btn btn-info btn-xs">total count: {people.length}</div>
                            </div> 
                        </div>
                        }
                        
                    </div>
                                    
                    {/* kolonne 2 */}
                    <div className="col-sm-5">

                            {
                                (() => {
                                    if (value.sub_title) {
                                    return  <div className="SubTitle px-3 py-1 mb-2">{value.sub_title}</div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.comment1) {
                                    return  <div className="card bg-light px-2 mb-2">
                                                <div className="quote2 px-1 pt-2 mb-3">{value.comment1}</div> 
                                                <div className="blockquote-footer"><i>{value.source1}</i></div>
                                            </div>
                                    }
                                    return 
                                })()
                            }

                            {
                                (() => {
                                    if (value.description) {
                                    return  <div className="factTextNoWrap rounded p-2 my-2">
                                                <ReactMarkdown>
                                                    {value.description}                     
                                                </ReactMarkdown>
                                            </div>
                                    }
                                    return 
                                })()
                            }
                            
                            {
                                (() => {
                                if (value.spotify) {
                                return  <div className='mb-2 ml-4'><b>Check out: <a href={ value.spotify }>
                                Spotify playlist</a></b></div>
                                }
                                return 
                                })()
                            }
                            
                            <div>

                                {
                                    (() => {
                                    if (value.url) {
                                    return  <div className="mt-2">
                                                <b>Source</b>
                                                <div className="Description">
                                                    <ul>
                                                        <li>
                                                            <b><a href={value.url}>
                                                                {value.name} ({value.source})
                                                            </a></b>
                                                        </li>
                                                        
                                                    </ul>
                                                </div>
                                            </div>
                                    }
                                    return 
                                    })()
                                }

                                <b>Check out</b>
                                <div className="Description">
                                    <ul>
                                        {
                                            (() => {
                                            if (value.link1 && value.link2 && value.link3 && value.link4) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url4}>
                                                            {value.link4}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2 && value.link3) {
                                                return  <div><li><b><a href={value.url1}>
                                                            {value.link1}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url2}>
                                                            {value.link2}
                                                        </a></b>
                                                        </li>
                                                        <li><b><a href={value.url3}>
                                                            {value.link3}
                                                        </a></b>
                                                        </li>
                                                        </div>
                                                }
                                            if (value.link1 && value.link2) {
                                            return  <div><li><b><a href={value.url1}>
                                                        {value.link1}
                                                    </a></b>
                                                    </li>
                                                    <li><b><a href={value.url2}>
                                                        {value.link2}
                                                    </a></b>
                                                    </li>
                                                    </div>
                                            }
                                            return 
                                            })()
                                        }


                                        <li><b><a href='/lists'>
                                            More Lists @ MusicThisDay.com
                                            </a></b>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                        
                    </div>

                    {/* kolonne 3 */}
                    <div className="col-sm-4 mb-1">

                        
                        {!no_filters &&
                        <div>
                            {!searchString  &&
                                <div className="row">   
                                    <div className="col">
                                        <h5>Filters</h5>
                                    </div>
                                </div>
                            }

                            {!searchString  && !nationality &&
                                <div className="row pb-2"> 
                                    <div className="col-6">
                                            <Select
                                                //styles={{control: customControlStyles}}
                                                value={optionCountry}
                                                onChange={selectedOptionCountry}
                                                options={CountryOptions}
                                                placeholder={'select country..'}

                                            />
                                    </div>

                                    <div className="col-6"> 
                                        <button type="button" className="btn btn-primary" onClick={() => setOptionCountry(null)}> Reset filter</button>
                                    </div> 

                                </div>
                            }

                            {!searchString  && nationality &&
                                <div className="row pb-2"> 
                                    <div className="col-6">
                                            <Select
                                                //styles={{control: customControlStyles}}
                                                value={optionNationality}
                                                onChange={selectedOptionNationality}
                                                options={NationalityOptions}
                                                placeholder={'select nationality..'}

                                            />
                                    </div>

                                    <div className="col-6"> 
                                        <button type="button" className="btn btn-primary" onClick={() => setOptionNationality(null)}> Reset filter</button>
                                    </div> 

                                </div>
                            }
                        </div>
                        }          

                        {!optionCountry &&
                        <div>
                            <div className="row">   
                                    <div className="col">
                                    <h4>Search</h4>
                                </div>
                            </div>
                                                
                            <div className="row mb-1">  
                                <div className="col-sm-6 mt-1">
                                    <div className="searchMedium">    
                                        <DebounceInput
                                            //type="number"
                                            minLength={3}
                                            debounceTimeout={500}
                                            value={searchString}
                                            onChange={handleSearchChange}
                                            placeholder="Search all names" 
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6 mt-1">
                                    <button type="button" className="btn btn-primary"   
                                        onClick={() => clearSearchField()} > Reset search</button>
                                </div>
                            </div>
                        </div>
                        }

                        {!hide_grid_option &&
                        <div className="form-check mt-4">
                            <input
                                type="checkbox"
                                id="checkbox"
                                checked={AgGrid}
                                onChange={checkHandler}
                                className="form-check-input"
                            />
                            <label htmlFor="checkbox">Change to Grid </label>
                        </div>
                        }
                
                    </div>

                </div>        
            </React.Fragment>
            )
    })

    const peopleItems = filterArray.map(value => { 

        let DescCharLimit, born, age, row_start_class, people_row_class, row_start_class_mobile

        if (!value.dead) {
            age = calculateAge(value.birth_date)
            //console.log('AGE' + age);
         } 

        if (value.born) {
            born = value.born;
        }
        else {
            born = value.bd_text;
        }

        if (value.charlimit_description) {
            DescCharLimit = value.charlimit_description;
        }
        if (value.band_charlimit_description) {
            DescCharLimit = value.band_charlimit_description;
        }
        if (value.comment && !value.charlimit_description) {
            DescCharLimit = 600;
        }
        if (value.comment_band && !value.charlimit_description && !value.band_charlimit_description) {
            DescCharLimit = 600;
        }
        if (!value.comment && !value.comment_band && !value.charlimit_description && !value.band_charlimit_description) {
            DescCharLimit = 1000;
        }
        if (!value.comment && !value.comment_band && !value.charlimit_description && !value.band_charlimit_description 
            && !value.youtube && !value.youtube1) {
            DescCharLimit = 800;
        }

        row_start_class = 'row border border-3 border-black mx-1 bg-light';

        people_row_class = 'row py-2 mb-4 border-start border-end border-bottom border-black border-2 mx-1';

        row_start_class_mobile = 'row HeaderSongsListsMobile border border-3 border-black mx-1 bg-light';
        console.log(isMobile);
        return (
            <React.Fragment key={value.artist}>
                
                {!isMobile && ranked_list &&
                <div className={row_start_class}>
                    <div className="col-2 HeaderSongsListsRank border-end border-3 border-black text-center">
                        <b>{value.place}</b>
                    </div>
                    <div className="col-10 HeaderSongsListsRank">
                        <b>{value.artist}</b>
                    </div>
                </div>
                }
                {!isMobile && !ranked_list &&
                <div className={row_start_class}>
                    <div className="col HeaderSongsListsRank">
                        <b>{value.artist}</b>
                    </div>
                </div>
                }

                {isMobile && ranked_list &&
                    <div className={row_start_class_mobile}>
                        <div className='col-2 HeaderSongsListsMobile border-end border-3 border-black text-center'>
                                <b>{value.place}</b>
                        </div>
                        <div className="col-10 HeaderSongsListsMobile">
                            <b>{value.artist}</b>
                        </div>
                    </div>
                }
                {isMobile && !ranked_list &&
                    <div className={row_start_class_mobile}>
                        <div className="col HeaderSongsListsMobile">
                            <b>{value.artist}</b>
                        </div>
                    </div>
                }

                {!value.youtube && !value.youtube1 &&
                    <div className={people_row_class}>

                        {/* kolonne 1 */}
                        <div className="col-sm-2 text-center mt-2">
                            <div align="center">
                                {value.band_picture &&
                                    <div>
                                        <img src={value.band_picture} className="img-fluid rounded" alt="">
                                        </img>
                                    </div>
                                }
                                {!value.band_picture &&
                                    <div>
                                        <img src={value.picture_link} className="img-fluid rounded" alt="">
                                        </img>
                                    </div>
                                }
                            </div>
                        </div>

                        {/* kolonne 2 */}
                        <div className="col-sm-6 mt-2">
    
                                {value.description && !value.band_description &&
                                    <div className="Description16">
                                        <ReadMoreAndLess
                                                charLimit={DescCharLimit}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                        <div className="Description my-1"> 
                                             <em> -> <a href={value.wikipedia_link}>wikipedia..</a></em>
                                            </div>
                                    </div>
                                }
                                {value.band_description && !value.description &&
                                    <div className="Description16">
                                        <ReadMoreAndLess
                                                charLimit={DescCharLimit}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.band_description}
                                        </ReadMoreAndLess>
                                        <div className="Description my-1"> 
                                             <em> -> <a href={value.band_wiki}>wikipedia..</a></em>
                                            </div>
                                    </div>
                                }

                                {value.main_comment &&
                                    <div className="card bg-light px-2 pt-2 mb-2">
                                        <p className="quote3">{value.main_comment}</p> 
                                        <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                    </div>
                                }

                                {value.comment && !value.main_comment && !value.source_details &&
                                    <div className="card bg-light px-2 pt-2">
                                        <p className="quote3">{value.comment}</p> 
                                        <div className="blockquote-footer"><i>{value.source}</i></div>
                                    </div>
                                }

                                {value.comment && !value.main_comment && value.source_details &&
                                    <div className="card bg-light px-2 pt-2">
                                        <p className="quote3">{value.comment}</p> 
                                        <div className="blockquote-footer"><i>{value.source} ({value.source_details})</i></div>
                                    </div>
                                }

                                {value.comment_band &&
                                    <div className="card bg-light px-2 pt-2">
                                        <p className="quote3">{value.comment_band}</p> 
                                        <div className="blockquote-footer"><i>{value.source_band} ({value.source_details_band})</i></div>
                                    </div>
                                }
                        </div>

                        {/* kolonne 3 */}
                        <div className="col-sm-4 mt-2">

                            <b>Born </b><br />
                                {age && born && value.city && !value.born_place &&
                                    <div>
                                        <div className="Date mb-2 p-1 rounded">{born} (age {age}) <br /> {value.city}
                                        , {value.region}, {value.country}</div> 
                                    </div>
                                }
                                {!age && born && value.city && !value.born_place &&
                                    <div>
                                        <div className="Date mb-2 p-1 rounded">{born} - {value.city}
                                        , {value.region}, {value.country}</div> 
                                    </div>
                                }

                                {age && born && !value.city && !value.born_place &&
                                    <div>
                                        <div className="Date mb-2 p-1 rounded">{born} (age {age})</div> 
                                    </div>
                                }
                                {!age && born && !value.city && !value.born_place &&
                                    <div>
                                        <div className="Date mb-2 p-1 rounded">{born}</div> 
                                    </div>
                                }

                                {age && born && value.born_place &&
                                    <div className="Date mb-2 p-1 rounded">{born} (age {age}) <br /> {value.born_place}</div>
                                }
                                {!age && born && value.born_place &&
                                    <div className="Date mb-2 p-1 rounded">{born} - {value.born_place}</div>
                                }

                                {(value.dead||value.dead_place) &&
                                    <div>
                                        {value.dead && value.dead_place &&
                                            <div>
                                                <b>Dead </b><br />
                                                <div className="Date mb-2 p-1 rounded">{value.dead} - {value.dead_place}
                                                </div> 
                                            </div>
                                        }
                                        {value.dead && !value.dead_place &&
                                            <div>
                                                <b>Dead </b><br />
                                                <div className="Date mb-2 p-1 rounded">{value.dead}
                                                </div> 
                                            </div>
                                        }
                                    </div>
                                }

                                {value.nationality && 
                                    <div>
                                        <b>Nationality </b><br />
                                        <div>
                                            <div className="factText mb-2 p-1 rounded">{value.nationality}</div>
                                        </div>
                                    </div>
                                }
                                {value.sub_genres && value.main_genre &&
                                    <div>
                                        <b>Genres </b><br />
                                        <div>
                                            <div className="factText mb-2 p-1 rounded">{value.main_genre}, {value.sub_genres}</div>
                                        </div>
                                    </div>
                                }
                                {value.sub_genres && !value.main_genre &&
                                    <div>
                                        <b>Genres </b><br />
                                        <div>
                                            <div className="factText mb-2 p-1 rounded">{value.main_genre}</div>
                                        </div>
                                    </div>
                                }
                                {value.instrument &&
                                <div>
                                    <b>Instruments </b><br />
                                    <div>
                                        <div className="factText mb-2 p-1 rounded">{value.instrument}</div>
                                    </div>
                                </div>
                                }
                                {value.associated_acts &&
                                <div>
                                    <b>Associated acts </b><br />
                                    <div>
                                        <div className="factText mb-2 p-1 rounded">{value.associated_acts}</div>
                                    </div>
                                </div>
                                }
                                {value.years_active_people &&
                                <div>
                                    <b>Years active </b><br />
                                    <div>
                                        <div className="factText p-1 rounded">{value.years_active_people}</div>
                                    </div>  
                                </div>
                                }

                        </div>

                    </div>
                }
                       
                    
                {(value.youtube || value.youtube1) &&
                <div className={people_row_class}>
                    
                    {/* kolonne 1 */}
                    <div className="col-sm-3 text-center mt-2">
                            
                        <div align="center">

                            {
                                (() => {
                                    if (value.band_picture) {
                                        return   <div className="mb-3"><img src={value.band_picture} className="img-fluid rounded" alt="">
                                        </img></div>
                                    }
                                    return <div className="mb-3"><img src={value.picture_link} className="img-fluid rounded" alt="">
                                    </img></div>
                                })()
                            }
                            
                        </div>

                    </div>

                    {/* kolonne 2 */}
                    <div className="col-sm-4 mt-2">

                        <div className="row">
                            
                            <div className="col px-2 border-right Description">

                                {
                                    (() => {
                                    if (value.description && !value.band_description) {
                                    return  <div>
                                        <ReadMoreAndLess
                                                charLimit={DescCharLimit}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.description}
                                        </ReadMoreAndLess>
                                        <div className="Description my-1"> 
                                             <em> -> <a href={value.wikipedia_link}>wikipedia..</a></em>
                                            </div>
                                    </div>
                                    }
                                    if (value.band_description && !value.description)
                                    return <div>
                                        <ReadMoreAndLess
                                                charLimit={DescCharLimit}
                                                readMoreText={" Read more ▼"}
                                                readLessText={" Read less ▲"}
                                                readMoreClassName="read-more-less--more"
                                                readLessClassName="read-more-less--less"
                                            >
                                            {value.band_description}
                                        </ReadMoreAndLess>
                                        <div className="Description my-1"> 
                                             <em> -> <a href={value.band_wiki}>wikipedia..</a></em>
                                            </div>
                                    </div>
                                    })()
                                }
                            </div>
                        </div>
                        <div className="row pt-3">
                                <div className="col-12">
                                    {value.main_comment &&
                                        <div className="card bg-light px-2 pt-2 mb-2">
                                            <p className="quote3">{value.main_comment}</p> 
                                            <div className="blockquote-footer"><i>{value.main_comment_source}</i></div>
                                        </div>
                                    }
                                    {
                                        (() => {
                                        if (value.comment && !value.main_comment && !value.source_details) {
                                            return <div className="card bg-light px-2 pt-2">
                                                        <p className="quote3">{value.comment}</p> 
                                                        <div className="blockquote-footer"><i>{value.source}</i></div>
                                                    </div>
                                        }
                                        if (value.comment && !value.main_comment && value.source_details) {
                                            return <div className="card bg-light px-2 pt-2">
                                                        <p className="quote3">{value.comment}</p> 
                                                        <div className="blockquote-footer"><i>{value.source} ({value.source_details})</i></div>
                                                    </div>
                                        }
                                        if (value.comment_band) {
                                            return <div className="card bg-light px-2 pt-2">
                                                        <p className="quote3">{value.comment_band}</p> 
                                                        <div className="blockquote-footer"><i>{value.source_band} ({value.source_details_band})</i></div>
                                                    </div>
                                        }
                                        return 
                                        })()
                                    }
                                </div>
                            </div> 

                    </div>
                    
                    {/* kolonne 3 */}
                    <div className="col-sm-3 mt-2">

                            <div className="row">

                                {
                                    (() => {
                                    if (value.description) {
                                    return  <div className="col-12">
                                                <b>Born </b><br />
                                                {age && born && value.city && !value.born_place &&
                                                    <div>
                                                        <div className="Date mb-2 p-1 rounded">{born} (age {age}) <br /> {value.city}
                                                        , {value.region}, {value.country}</div> 
                                                    </div>
                                                }
                                                {!age && born && value.city && !value.born_place &&
                                                    <div>
                                                        <div className="Date mb-2 p-1 rounded">{born} - {value.city}
                                                        , {value.region}, {value.country}</div> 
                                                    </div>
                                                }

                                                {age && born && !value.city && !value.born_place &&
                                                    <div>
                                                        <div className="Date mb-2 p-1 rounded">{born} (age {age})</div> 
                                                    </div>
                                                }
                                                {!age && born && !value.city && !value.born_place &&
                                                    <div>
                                                        <div className="Date mb-2 p-1 rounded">{born}</div> 
                                                    </div>
                                                }

                                                {age && born && value.born_place &&
                                                    <div className="Date mb-2 p-1 rounded">{born} (age {age}) <br /> {value.born_place}</div>
                                                }
                                                {!age && born && value.born_place &&
                                                    <div className="Date mb-2 p-1 rounded">{born} - {value.born_place}</div>
                                                }


                                                {
                                                    (() => {
                                                    if (value.dead||value.dead_place) {
                                                        if (value.dead && value.dead_place) {
                                                            return <div>
                                                                <b>Dead </b><br />
                                                                <div className="Date mb-2 p-1 rounded">{value.dead} - {value.dead_place}
                                                                </div> 
                                                            </div>
                                                        }
                                                        if (value.dead) {
                                                            return <div>
                                                                    <b>Dead </b><br />
                                                                    <div className="Date mb-2 p-1 rounded">{value.dead}
                                                                    </div> 
                                                                </div>
                                                        }
                                                        return <div>
                                                                <b>Dead </b><br />
                                                                <div className="Date mb-2 p-1 rounded">{value.dead_place}
                                                                </div> 
                                                            </div>
                                                        }
                                                    })()
                                                }

                                                {value.nationality && 
                                                    <div>
                                                        <b>Nationality </b><br />
                                                        <div>
                                                            <div className="factText mb-2 p-1 rounded">{value.nationality}</div>
                                                        </div>
                                                    </div>


                                                }
                                            

                                                {
                                                    (() => {
                                                        if (value.sub_genres && value.main_genre) {
                                                         return   <div>
                                                                <b>Genres </b><br />
                                                                <div>
                                                                    <div className="factText mb-2 p-1 rounded">{value.main_genre}, {value.sub_genres}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        if (value.main_genre || !value.sub_genres) {
                                                            return <div>
                                                                <b>Genre </b><br />
                                                                <div>
                                                                    <div className="factText mb-2 p-1 rounded">{value.main_genre}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        return 
                                                    })()
                                                }

                                                {
                                                    (() => {
                                                        if (value.instrument) {
                                                         return   <div>
                                                                <b>Instruments </b><br />
                                                                <div>
                                                                    <div className="factText mb-2 p-1 rounded">{value.instrument}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        return
                                                    })()
                                                }

                                                {   
                                                    (() => {
                                                        if (value.associated_acts) {
                                                         return   <div>
                                                                <b>Associated acts </b><br />
                                                                <div>
                                                                    <div className="factText mb-2 p-1 rounded">{value.associated_acts}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        return
                                                    })()
                                                }

                                                {   
                                                    (() => {
                                                        if (value.years_active_people) {
                                                         return   <div>
                                                                <b>Years active </b><br />
                                                                <div>
                                                                    <div className="factText p-1 rounded">{value.years_active_people}</div>
                                                                </div>  
                                                            </div>
                                                        }
                                                        return
                                                    })()
                                                }

                                                    

                                            </div>
                                    }
                                    if (value.duo_description) {
                                    return <div className="col-12">
                                                <b>Born </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1">{value.duo_description}</div>
                                                </div>

                                                <b>Genres </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1">{value.genres}</div>
                                                </div>

                                                {   
                                                    (() => {
                                                        if (value.years_active) {
                                                         return   <div>
                                                                <b>Years active </b><br />
                                                                <div>
                                                                    <div className="factText mb-2 p-1">{value.years_active}</div>
                                                                </div>
                                                            </div>
                                                        }
                                                        return
                                                    })()
                                                }

                                                

                                        </div>
                                    }
                                    return <div className="col-12">
                                                <b>Origin</b><br />

                                                {
                                                    (() => {
                                                        if (value.origin_place) {
                                                         return   <div>
                                                                    <div className="factText mb-2 p-1 rounded">{value.origin_place}</div>
                                                                </div>
                                                        }
                                                        return <div>
                                                                    <div className="factText mb-2 p-1 rounded">{value.city}, {value.country}</div>
                                                                </div>
                                                    })()
                                                }

                                                
                                                <b>Years active </b><br />
                                                <div>
                                                    <div className="factText mb-2 p-1 rounded">{value.years_active}</div>
                                                </div>

                                                <b>Genres </b><br />
                                                <div>
                                                    <div className="factText p-1 mb-2 rounded">{value.genres}</div>
                                                </div>

                                                {value.members &&
                                                    <div>
                                                        <b>Members </b><br />
                                                        <div>
                                                            <div className="factText mb-2 p-1 rounded">{value.members}</div>
                                                        </div>

                                                    </div>
                                                }
                                                {value.past_members &&
                                                    <div>
                                                        <b>Past members </b><br />
                                                        <div>
                                                            <div className="factText mb-2 p-1 rounded">
                                                                <ReadMoreAndLess
                                                                        charLimit={100}
                                                                        readMoreText={" Read more ▼"}
                                                                        readLessText={" Read less ▲"}
                                                                        readMoreClassName="read-more-less--more"
                                                                        readLessClassName="read-more-less--less"
                                                                    >
                                                                    {value.past_members}
                                                                </ReadMoreAndLess>
                                                            </div>
                                                        </div>

                                                    </div>
                                                }

                                        </div>
                                    })()
                                }

                            </div>  

                            <div className="row pt-3">
                                <div className="col-12">
                                    {value.comment && !value.source_details && value.main_comment &&
                                        <div className="card bg-light px-2 pt-2 mb-2">
                                            <p className="quote3">{value.comment}</p> 
                                            <div className="blockquote-footer"><i>{value.source}</i></div>
                                        </div>
                                    }
                                    {value.comment && value.source_details && value.main_comment &&
                                        <div className="card bg-light px-2 pt-2 mb-2">
                                            <p className="quote3">{value.comment}</p> 
                                            <div className="blockquote-footer"><i>{value.source} ({value.source_details})</i></div>
                                        </div>
                                    }
                                </div>
                            </div>

                            {value.band_main_comment &&
                                <div className="card bg-light px-2 pt-2 mb-2">
                                    <p className="quote3">{value.band_main_comment}</p> 
                                    <div className="blockquote-footer"><i>{value.band_main_comment_source}</i></div>
                                </div>
                            }

                        
                        </div>
                    {/* kolonne 4 */}
                    <div className="col-sm-2 mt-2">
                        {
                            (() => {
                            if (value.youtube) {
                                return  <div className="text-center">
                                            <div>
                                                <h4><div className="badge bg-secondary text-wrap px-2">Selected songs</div></h4>
                                                <div className="border bg-light mt-1 rounded">
                                                    <ReactPlayer
                                                    url={ value.youtube }
                                                    light='true'
                                                    volume={0.9}
                                                    controls={true}
                                                    width="426"
                                                    height="240"
                                                    />
                                                </div>
                                            </div>
                                            {
                                                (() => {
                                                if (value.youtube_text_band) {
                                                return <div className='factText2 mt-1 rounded'>
                                                            <strong>{value.youtube_text_band}</strong>
                                                        </div>
                                                }
                                                return <div className='factText2 mt-1 rounded'>
                                                            <strong>{value.youtube_text}</strong>
                                                        </div>
                                                })()
                                            }
                                            
                                        </div>
                            }
                            return 
                            })()
                        }
                        {value.youtube1 &&
                            <div className="text-center mt-4">
                                <div>
                                    <div className="border bg-light mt-1 rounded">
                                        <ReactPlayer
                                        url={ value.youtube1 }
                                        light='true'
                                        volume={0.9}
                                        controls={true}
                                        width="426"
                                        height="240"
                                        />
                                    </div>
                                </div>
                                <div className='factText2 mt-1 rounded'>
                                    <strong>{value.song1_txt}</strong>
                                </div>

                            </div>
                        }
                        {value.youtube2 &&
                            <div className="text-center mt-4">
                                <div>
                                    <div className="border bg-light mt-1 rounded">
                                        <ReactPlayer
                                        url={ value.youtube2 }
                                        light='true'
                                        volume={0.9}
                                        controls={true}
                                        width="426"
                                        height="240"
                                        />
                                    </div>
                                </div>
                                <div className='factText2 mt-1 rounded'>
                                    <strong>{value.song2_txt}</strong>
                                </div>

                            </div>
                        }
                        {value.video1 &&
                            <div className="text-center mt-4">
                                <div>
                                    <div className="border bg-light mt-1 rounded">
                                        <ReactPlayer
                                        url={ value.video1 }
                                        light='true'
                                        volume={0.9}
                                        controls={true}
                                        width="426"
                                        height="240"
                                        />
                                    </div>
                                </div>
                                <div className='factText2 mt-1 rounded'>
                                    <strong>{value.title1}</strong>
                                </div>

                            </div>
                        }
                        {value.video2 &&
                            <div className="text-center mt-4">
                                <div>
                                    <div className="border bg-light mt-1 rounded">
                                        <ReactPlayer
                                        url={ value.video2 }
                                        light='true'
                                        volume={0.9}
                                        controls={true}
                                        width="426"
                                        height="240"
                                        />
                                    </div>
                                </div>
                                <div className='factText2 mt-1 rounded'>
                                    <strong>{value.title2}</strong>
                                </div>

                            </div>
                        }
                        {value.video3 &&
                            <div className="text-center mt-4">
                                <div>
                                    <div className="border bg-light mt-1 rounded">
                                        <ReactPlayer
                                        url={ value.video3 }
                                        light='true'
                                        volume={0.9}
                                        controls={true}
                                        width="426"
                                        height="240"
                                        />
                                    </div>
                                </div>
                                <div className='factText2 mt-1 rounded'>
                                    <strong>{value.title3}</strong>
                                </div>

                            </div>
                        }
                    </div>
                            
                </div>
                }

            </React.Fragment>          
        )

    })

     //REFERENCES
     const listReferences = sortedReferences.map(value => {
        return (
            <React.Fragment key={value.id}>

                {
                    (() => {
                    if (value.book_title) {
                    return  <div className="row px-1 my-1">
                                <div className="col">
                                    <div>{value.author_txt} ({value.published_year}).
                                    <a href={value.more_info}> {value.book_title}</a>. {value.publisher}. (book) </div>
                                </div>
                            </div>
                    } if (value.person && !value.date_txt) {
                    return <div className="row px-1 my-1">
                            <div className="col">
                                <a href={value.url}>
                                    {value.title}
                                </a> ({value.person})
                            </div>
                        </div>
                    } if (!value.person && value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> ({value.date_txt})
                                    </div>
                                </div>
                    }
                    if (!value.person && !value.date_txt) {
                        return <div className="row px-1 my-1">
                                    <div className="col">
                                        <a href={value.url}>
                                            {value.title}
                                        </a> 
                                    </div>
                                </div>
                    }
                    return <div className="row px-1 my-1">
                                <div className="col">
                                    {value.person} ({value.date_txt}). <a href={value.url}>
                                        {value.title}
                                    </a>
                                </div>
                            </div>
                    })()
                }

                
            </React.Fragment>
        )
    })

    return (
        <main className="my-1 py-1">
            {list.length > 0 && (
                <div className="container">

                    <SEO
                    title={name}
                    description={description}
                    name={name}
                    picture_link={pic_link}
                    url={'https://www.musicthisday.com/'+page}
                    type='article' />


                    <div className="row">
                        <div className="col">
                            {listHeading}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col pb-2" align="Right"> 
                            <button type="button" className="btn btn-primary btn-sm" onClick={scrollToBottom}>
                            <b>Scroll down</b>
                            </button>
                        </div>
                    </div>

                    {!AgGrid && 
                    <div className="row">
                        <div className="col">

                            {
                                (() => {
                                if (peopleItems.length > 0) {
                                return <div> {peopleItems} </div>
                                }
                                return <div>
                                        <h3>No people found</h3>

                                    </div>
                                })()
                            }

                        </div>
                    </div>
                    }

                    {AgGrid && 
                    <div className="row pr-2 pl-2 pt-3">
                        <div id="myGrid" style={{ height: '2000px', width: '1200px' }} className="ag-theme-balham">
                            <AgGridReact
                                rowSelection="multiple"
                                pagination={true}
                                rowData={filterArray}
                                columnDefs={columnDefs}>
                            </AgGridReact>
                        </div>
                    </div>
                    }

                    <p align="Right"><br />
                        <button type="button" className="btn btn-primary btn-sm" onClick={scrollToTop}>
                        <b>Scroll to top</b></button>
                    </p>

                    {(references.length > 0) &&
                        <div>
                            <div className="row mt-4">
                                <div className="col">
                                        <h4>References & similar lists</h4>
                                </div>
                            </div>
                            <div className="row px-1">
                                <div className="col">
                                    <b>
                                    <a href='https://en.wikipedia.org'>
                                        Wikipedia
                                    </a>
                                    </b>
                                </div>
                            </div>
                        
                            <div className="row mt-2">
                                <div className="col">
                                        { listReferences }
                                </div>
                            </div>
                        </div>  

                    }

                </div>
                
            )}
        </main>
    )


}

export default PeopleLists