import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDaySongs } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import { Link } from "react-router-dom";

const AnyDaySongs = (props) => {
  const [songs, setSongs] = useState([])
  const [showAllSongs, setShowAllSongs] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = React.useState(null);
  let largeArray

  const ShowAllSongsButton = () => {
    setShowAllSongs(true);
    };
  
    
    useEffect(() => {
      const fetchSongs = () => {
        axios.get(dbURLAnyDaySongs + props.dateSelected)
        .then(response => {
          setIsLoading(false)
          setSongs(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchSongs()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  if (isLoading) {
    return <div>
            <main className="my-4 py-4"><div className="container"> 
            <div className="row pt-5"><div className="col">
            <h4>Loading...</h4></div></div></div></main>
        </div>
}

  //fjerne duplikater
    const songsUnique  = songs.filter(
        (obj, index) =>
          songs.findIndex((item) => item.id === obj.id) === index
      );

  //Fjerne objecter over 6 
  if (songsUnique.length > 6 && !showAllSongs) {
    largeArray = true
    songsUnique.length = 6; 
  }

  

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {songsUnique.length === 0 &&
         <div className="ml-1 mt-2 mt-md-0">
            <div className="row TodayTitle24nobg mx-5">
                <div className="col-sm-12"> 
                    <div className="text-center"><b>Songs</b>
                    </div>
                    <h5>--No songs on this day--</h5>
                </div>
            </div>

        </div>
      }
      {songsUnique.length > 0 && (
        <div className="ml-2 mt-2 mt-md-0">
            <h2 className="text-center"><strong>SONGS</strong></h2>
          {songsUnique.map(value => (

            <div key={value.id}>
                  <div className="row">
                    <div className="col">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body">
                                {!value.youtube &&
                                    <h5 className="card-title Good rounded py-2 ps-2 pe-1">{value.title}</h5>
                                }
                                {value.youtube &&
                                    <h5 className="card-title rounded py-2 ps-2 pe-1 d-flex align-items-center custom-gradient-anyday-title">
                                        {value.title}
                                            <a href={value.youtube} target="_blank" rel="noopener noreferrer" className="ms-auto">
                                                <img 
                                                    alt="YouTube" 
                                                    className="float-end my-auto" 
                                                    style={{ height: "30px", width: "30px" }} 
                                                    src="https://imagedelivery.net/IScjm-nE3STjiSKq2Kx0QQ/520f385c-dd23-4e0d-684c-96c7ddeeb600/width=30,height=30" 
                                                />
                                            </a>
                                    </h5>
                                }
                                <div className="card-text">

                                    <div className="row ReducedText">
                                        <div className="col-4">
                                            {
                                                (() => {
                                                if (value.pic_link && !value.picture) {
                                                    return <div align="center"><img src={value.pic_link} 
                                                            alt="" className="img-fluid rounded">
                                                            </img></div>
                                                    }
                                                if (value.picture) {
                                                    return <div align="center"><img src={value.picture} 
                                                            alt="" className="img-fluid rounded">
                                                            </img></div>
                                                    }
                                                if (value.album_picture && !value.pic_link && !value.picture) {
                                                    return <div align="center"><img src={value.album_picture} 
                                                        alt="" className="img-fluid rounded">
                                                        </img></div>
                                                    }
                                                return 
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.aka) {
                                                return <div align="center" className="SmallText">
                                                <b>{value.aka}</b></div> 
                                                }
                                                if (value.artist_text) {
                                                    return <div align="center" className="SmallText">
                                                <b>{value.artist_text}</b></div> 
                                                }
                                                return <div align="center" className="SmallText">
                                                <b>{value.artist}</b></div> 
                                                })()
                                            }
                                        </div>

                                        <div className="col-8">

                                        
                                            {
                                                (() => {
                                                    if (props.dateSelected === value.released_slug) {
                                                        return <div>
                                                        <b>Released: </b><br />
                                                        <div className="DateInline p-1 rounded"><b>{value.single_released}</b></div>
                                                        </div> 
                                                    }
                                                    if (value.released_slug && (props.dateSelected !== value.released_slug)) {
                                                        return <div>
                                                        <b>Released:</b> <br />
                                                        <div className="DateInline p-1 rounded">{value.single_released}</div>
                                                        </div> 
                                                    }
                                                    if (!value.released_slug && value.sr_text) {
                                                    return <div>
                                                        <b>Released:</b><br />
                                                        <div className="DateInline p-1 rounded"> {value.sr_text}</div>
                                                        </div>
                                                    }
                                                    return
                                                })()
                                            }
                                            {
                                                (() => {
                                                if (value.recorded_slug === props.dateSelected) {
                                                    return <div>
                                                    <b>Recorded:</b> <br />
                                                        <div className="DateInline p-1 rounded"> <b>{value.recorded}</b></div>
                                                    </div> 
                                                }
                                                if (value.recorded_slug && (value.recorded_slug !== props.dateSelected)) {
                                                    return <div>
                                                    <b>Recorded:</b><br />
                                                        <div className="DateInline p-1 rounded">{value.recorded}</div>
                                                    </div> 
                                                }
                                                if (value.rd_text) {
                                                    return <div>
                                                        <b>Recorded</b><br/> 
                                                        <div className="Date p-1 rounded">
                                                            <ReadMoreAndLess
                                                                    charLimit={70}
                                                                    readMoreText={" more"}
                                                                    readLessText={" less"}
                                                                    readMoreClassName="read-more-less--more"
                                                                    readLessClassName="read-more-less--less"
                                                                >
                                                                {value.rd_text}
                                                            </ReadMoreAndLess> 
                                                        </div>
                                                    </div>
                                                }
                                                return 
                                                })()
                                            }

                                            {value.album && !value.album_rd &&
                                                <div><b>Album</b> <br />
                                                    <div className="factText p-1 rounded">{value.album}</div>      
                                                </div>
                                            }
                                            {value.album && value.album_rd &&
                                                <div><b>Album</b> <br />
                                                    <div className="Date p-1 rounded">{value.album} <br /> <em>{value.album_rd}</em></div>     
                                                </div>
                                            }

                                            {!value.album && value.album_txt &&
                                                <div><b>Album</b> <br />
                                                    <div className="factText p-1 rounded">{value.album_txt}</div>
                                                </div>
                                            }

                                           

                                            {
                                                (() => {
                                                if (value.live_version) {
                                                    return <div className="mt-1">
                                                        <button type="button" className="btn btn-info btn-xs">Live</button>
                                                    </div>
                                                }
                                                if (value.not_released) {
                                                    return <div className="mt-1">
                                                        <button type="button" className="btn btn-info btn-xs">Not released</button>
                                                    </div>
                                                }
                                                return
                                                })()
                                            }
                                            
                                        </div>
                                    </div>

                                    <div className="row mt-2 ReducedText">

                                    <div className="col">
                                        {
                                            (() => {
                                            if (value.description) {
                                            return <div className="Description mb-2">
                                                        <ReadMoreAndLess
                                                            charLimit={150}
                                                            readMoreText={" more▼"}
                                                            readLessText={" less▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                            >
                                                            {value.description}
                                                        </ReadMoreAndLess>
                                                        <em> -> <a href={value.wiki}>wikipedia</a></em>
                                                    </div>
                                            }
                                            return 
                                        })()       
                                        }
                                        {
                                            (() => {
                                            if (value.listsongid && value.list_name_unranked) {
                                            return <div className="card p-2 mb-2 Description bg-light">
                                                        <div>
                                                        <b>Check out lists:</b><br /> <Link style = {{'fontSize': '14px'}} 
                                                        className="font-weight-bold text-wrap-2" 
                                                            to={value.list_page}>{value.list_name}</Link> (#{value.list_place})
                                                        </div>
                                                        <div><Link style = {{'fontSize': '14px'}} 
                                                        className="font-weight-bold text-wrap-2" 
                                                            to={value.list_unranked_page}>{value.list_name_unranked}</Link>
                                                        </div>
                                                    </div>
                                            }
                                            return 
                                            })()    
                                        }

                                        {
                                            (() => {
                                            if (value.listsongid && !value.list_name_unranked) {
                                            return <div className="card p-2 mb-2 Description bg-light">
                                                        <div>
                                                        <b>Check out list:</b><br /> <Link style = {{'fontSize': '14px'}} 
                                                        className="font-weight-bold text-wrap-2" 
                                                            to={value.list_page}>{value.list_name}</Link> (#{value.list_place})
                                                        </div>
                                                    </div>
                                            }
                                            return 
                                            })()    
                                        }
                                        {
                                            (() => {
                                            if (value.list_name_unranked && !value.listsongid) {
                                            return <div className="card p-2 mb-2 Description bg-light">
                                                        <div>
                                                        <b>Check out list:</b><br /> <Link style = {{'fontSize': '14px'}} 
                                                        className="font-weight-bold text-wrap-2" 
                                                            to={value.list_unranked_page}>{value.list_name_unranked}</Link>
                                                        </div>
                                                    </div>
                                            }
                                            return 
                                            })()
                                                
                                        }
                                    </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                

               
                
            </div>
          ))}
          {largeArray &&
        <div className='mt-2'>
          <button id = "btn_songs" className="btn-sm btn-info" onClick = {ShowAllSongsButton}>Show more songs</button>
          </div>
        }
        
        </div>
      )}
    </div>
  )
}

export default AnyDaySongs
