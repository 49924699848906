import React, { useEffect, useState } from "react"
import axios from "axios"
import { dbURLAnyDayPeople } from '../Constants';
import ReadMoreAndLess from "react-read-more-less";
import { Link } from "react-router-dom";
import { ImageWithFallback, calculateAge } from "../misc/utils";

const isAdmin = localStorage.getItem("adminModeEgil") === "true";

const AnyDayPeople = (props) => {
const [people, setPeople] = useState([]);
const [showAllPeople, setShowAllPeople] = useState(false);
const [isLoading, setIsLoading] = useState(true)
const [error, setError] = React.useState(null);
let largeArray

const ShowAllPeopleButton = () => {
    setShowAllPeople(true);
    };
    
    useEffect(() => {
      const fetchPeople = () => {
        axios.get(dbURLAnyDayPeople + props.dateSelected)
        .then(response => {
          setIsLoading(false)
          setPeople(response.data)
        }).catch(error => {
          setError(error);
        });
      }
      fetchPeople()
    }, [props.dateSelected])

  if (error) return `Error: ${error.message}`;

  if (isLoading) {
    return <div>
            <main className="my-4 py-4"><div className="container"> 
            <div className="row pt-5"><div className="col">
            <h4>Loading...</h4></div></div></div></main>
        </div>
}
 
//let textToCopy;

  //fjerne duplikater
  const peopleUnique  = people.filter(
    (obj, index) =>
      people.findIndex((item) => item.id === obj.id) === index
  );

  //Fjerne objecter over 8 
  if (peopleUnique.length > 6  && !showAllPeople) {
    largeArray = true
    peopleUnique.length = 6;
    }

   // Function to copy person details to clipboard
   const handleCopy = async (person) => {

    const ageCopy = person.dead ? null : calculateAge(person.birth_date);

    const textToCopy = `${ageCopy ? `🎉 Happy ${ageCopy} Birthday, ${person.aka || person.name}!\n-> https://musicthisday.com/` 
    : `🕊 Remembering ${person.aka || person.name}\n${person.born.replace(/\s+/g, " ")} - ${person.dead.replace(/\s+/g, " ")}\n-> https://musicthisday.com/`}
${person.list_name ? `-> https://www.musicthisday.com/${person.list_page}\n` : ""}\n${person.description}`;


        try {
        await navigator.clipboard.writeText(textToCopy);
        alert(`Copied to clipboard:\n\n${textToCopy}`);
        } catch (err) {
        console.error("Failed to copy:", err);
        }
    };

  return (
    <div>
      {/* {isLoading && <p>Loading...</p>} */}
      {peopleUnique.length > 0 && (
        <div>
            <h2 className="text-center"><strong>PEOPLE</strong></h2>
          {peopleUnique.map(value => {
          
          /* let age
          if (!value.dead) {
            age = calculateAge(value.birth_date)
            } */
        
        const age = value.dead ? null : calculateAge(value.birth_date);


          return (
            <div key={value.id}>

                <div className="row">
                    <div className="col">
                        <div className="card shadow-sm mb-4">
                            <div className="card-body">

                                {value.aka &&
                                    <h5 className="card-title Good rounded py-2 ps-2">{value.aka}</h5>
                                }
                                {!value.aka &&
                                    <h5 className="card-title Good rounded py-2 ps-2">{value.name}</h5>
                                }
                                <div className="card-text">
                                    {value.picture &&
                                        <div className="row ReducedText">
                                            <div className="col-4">

                                                {value.picture &&
                                                    <div align="center">
                                                        <ImageWithFallback src={value.picture}  
                                                        alt="description" />
                                                    {/*  <img src={value.picture} 
                                                    alt="" className="img-fluid rounded">
                                                    </img> */}
                                                    </div>
                                                }
                                                
                                                
                                            </div>

                                            <div className="col-8">

                                                {age && props.dateSelected === value.born_slug &&
                                                    <div>
                                                        <b>Born: </b><br />
                                                        <div className="DateInline p-1 rounded"><b>{value.born}</b> (age {age})</div>
                                                    </div>
                                                }
                                                {!age && props.dateSelected === value.born_slug &&
                                                    <div>
                                                        <b>Born: </b><br />
                                                        <div className="DateInline p-1 rounded"><b>{value.born}</b></div>
                                                    </div>
                                                }


                                                {props.dateSelected !== value.born_slug &&
                                                    <div>
                                                        <b>Born:</b><br />
                                                        <div className="DateInline p-1 rounded">{value.born}</div>
                                                    </div>
                                                }
                                                
                                                {
                                                    (() => {
                                                    if (props.dateSelected === value.dead_slug) {
                                                    return <div>
                                                            <b>Died: </b><br />
                                                            <div className="DateInline p-1 rounded"><b>{value.dead}</b></div>
                                                        </div>
                                                    }
                                                    if (value.dead_slug && (props.dateSelected !== value.dead_slug)) {
                                                        return <div>
                                                                <b>Died:</b><br />
                                                                <div className="DateInline p-1 rounded">{value.dead}</div>
                                                            </div>
                                                    }
                                                    return 
                                                    })()
                                                }

                                                {value.genre && !value.band &&
                                                    <div>
                                                        <b>Genre: </b><br />
                                                        <div className="DateInline p-1 rounded"><b>{value.genre}</b></div>
                                                    </div>
                                                }

                                                {
                                                    (() => {
                                                        if (value.band) {
                                                        return <div className="mt-1"><b>Main band</b><br />
                                                        <div className="DateInline p-1 rounded">{value.band}</div></div>
                                                        }
                                                        return
                                                    })()
                                                }
                                                {
                                                    (() => {
                                                        if (value.city && !value.born_place) {
                                                        return <div className="mt-1"><b>Born/origin</b><br />
                                                        <div className="DateInline p-1 rounded">{value.city}, {value.country}</div></div>
                                                        }
                                                        if (value.born_place) {
                                                            return <div className="mt-1"><b>Born/origin</b><br />
                                                        <div className="DateInline p-1 rounded">{value.born_place}</div></div>
                                                        }
                                                        return 
                                                    })()
                                                }


                                            </div>
                                        </div>
                                    }
                                    {!value.picture &&
                                        <div>
                                            <div className="row ReducedText">
                                                <div className="col-5">
                                                    {
                                                        (() => {
                                                        if (props.dateSelected === value.born_slug) {
                                                        return <div>
                                                                <b>Born: </b><br />
                                                                <div className="DateInline p-1 rounded"><b>{value.born}</b></div>
                                                            </div>
                                                        }
                                                        return <div>
                                                                <b>Born:</b><br />
                                                                <div className="DateInline p-1 rounded">{value.born}</div>
                                                            </div>
                                                        })()
                                                    }
                                                    
                                                    {
                                                        (() => {
                                                        if (props.dateSelected === value.dead_slug) {
                                                        return <div>
                                                                <b>Died: </b><br />
                                                                <div className="DateInline p-1 rounded"><b>{value.dead}</b></div>
                                                            </div>
                                                        }
                                                        if (value.dead_slug && (props.dateSelected !== value.dead_slug)) {
                                                            return <div>
                                                                    <b>Died:</b><br />
                                                                    <div className="DateInline p-1 rounded">{value.dead}</div>
                                                                </div>
                                                        }
                                                        return 
                                                        })()
                                                    }

                                                    {value.genre && !value.band &&
                                                        <div>
                                                            <b>Genre: </b><br />
                                                            <div className="DateInline p-1 rounded"><b>{value.genre}</b></div>
                                                        </div>
                                                    }

                                                    {
                                                        (() => {
                                                            if (value.band) {
                                                            return <div className="mt-1"><b>Main band</b><br />
                                                            <div className="DateInline p-1 rounded">{value.band}</div></div>
                                                            }
                                                            return
                                                        })()
                                                    }
                                                    {
                                                        (() => {
                                                            if (value.city && !value.born_place) {
                                                            return <div className="mt-1"><b>Born/origin</b><br />
                                                            <div className="DateInline p-1 rounded">{value.city}, {value.country}</div></div>
                                                            }
                                                            if (value.born_place) {
                                                                return <div className="mt-1"><b>Born/origin</b><br />
                                                            <div className="DateInline p-1 rounded">{value.born_place}</div></div>
                                                            }
                                                            return 
                                                        })()
                                                    }
                                                    
                                                </div>

                                                <div className="col-7">
                                                    
                                                    <div className="Description mb-2">
                                                        <ReadMoreAndLess
                                                            charLimit={220}
                                                            readMoreText={" more▼"}
                                                            readLessText={" less▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                            >
                                                            {value.description}
                                                        </ReadMoreAndLess>
                                                        <em> -> <a href={value.wiki}>wikipedia</a></em>
                                                    </div>
                                                    
                                                </div>
                                            </div>

                                            {value.list_name &&
                                                <div className="card p-2 mb-2 Description bg-light">
                                                        <b>Check out list:</b>
                                                            <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                                            to={value.list_page}>{value.list_name}</Link> 
                                                    </div>
                                            }
                                        </div>
                                    }

                                    {value.picture &&
                                        <div className="row mt-2 ReducedText">
                                            <div className="col">
                                                {value.description &&
                                                    <div className="Description mb-2">
                                                        <ReadMoreAndLess
                                                            charLimit={150}
                                                            readMoreText={" more▼"}
                                                            readLessText={" less▲"}
                                                            readMoreClassName="read-more-less--more"
                                                            readLessClassName="read-more-less--less"
                                                            >
                                                            {value.description}
                                                        </ReadMoreAndLess>
                                                        <em> -> <a href={value.wiki}>wikipedia</a></em>
                                                    </div>
                                                }

                                                {value.list_name &&
                                                    <div className="card p-2 mb-2 Description bg-light">
                                                            <b>Check out list:</b>
                                                                <Link style = {{'fontSize': '14px'}} className="font-weight-bold text-wrap-2" 
                                                                to={value.list_page}>{value.list_name}</Link> 
                                                        </div>
                                                }

                                            </div>
                                        </div>
                                    }
                                </div>
                                {isAdmin && (
                                    <button className="btn btn-primary btn-xxs float-end" onClick={() => handleCopy(value)}>
                                        Copy
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          )})}
        {largeArray &&
        <div className='mb-4'>
          <button id = "btn" className="btn-sm btn-info rounded" onClick = {ShowAllPeopleButton}>Show more people</button>
          </div>
        }
        {/* {showAllPeople &&
        <div className='mt-2'>
          <button id = "btn" className="btn-sm btn-info" onClick = {ShowLessPeopleButton}>Show less people</button>
          </div>
        } */}
        </div>
      )}
    </div>
  )
 
}

export default AnyDayPeople
